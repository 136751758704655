import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

/** Components */
import Seo from '@components/seo';
import MainContainer from '@components/main.container';

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const NotFoundPage = (): JSX.Element => {
  return (
    <Fragment>
      <Seo />
      <MainContainer>
        <section className="row m-0 mt-5">
          <div className="col-12 d-flex justify-content-center align-items-center mt-5 mb-5">
            <StaticImage
              src="../images/404.png"
              alt="404"
              className="img-fluid"
            />
          </div>
        </section>
      </MainContainer>
    </Fragment>
  );
};

export default NotFoundPage;
